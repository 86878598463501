import { PlayerFooter } from '@/features/audio-player/ui/player-footer/player-footer';
import { usePlayerData } from '@/features/audio-player/hooks/use-player-data';
import hardPush from '@/shared/utils/hard-push';
import { useDeleteFavorite, usePostFavorite } from '@/entities/user';
import { Text } from '@chakra-ui/react';
import IconHeartFill from '@ui-kit/icons/icon-heart-fill';
import IconHeartBroken from '@ui-kit/icons/icon-heart-broken';
import { useAlert } from '@ui-kit/hooks/useAlert';
import { useTranslations } from 'next-intl';

const FavoriteAlertDescription = ({
  isAdded = false,
  isSuccess,
}: {
  isSuccess: boolean;
  isAdded: boolean;
}) => {
  const t = useTranslations();
  if (!isSuccess) {
    return (
      <Text textStyle='text-regular-lg'>
        {t.rich('FavoriteAlertDescription.error', {
          kind: isAdded ? 'added' : 'deleted',
          color: (chunks) => (
            <Text
              color='text&icon.tx-accent'
              as='span'
            >
              {chunks}
            </Text>
          ),
        })}
      </Text>
    );
  }

  return (
    <Text textStyle='text-regular-lg'>
      {t.rich('FavoriteAlertDescription.success', {
        kind: isAdded ? 'added' : 'deleted',
        color: (chunks) => (
          <Text
            color='text&icon.tx-accent'
            as='span'
          >
            {chunks}
          </Text>
        ),
      })}
    </Text>
  );
};

const useFavoriteAlert = (status: 'add' | 'delete') => {
  const isAdd = status == 'add';
  const icon = isAdd ? (
    <IconHeartFill color='text&icon.tx-accent' />
  ) : (
    <IconHeartBroken color='text&icon.tx-accent' />
  );
  const showSuccessAlert = useAlert({ status: 'success' });
  const showErrorAlert = useAlert({ status: 'error' });

  return (isSuccess: boolean) => {
    const description = (
      <FavoriteAlertDescription
        isSuccess={isSuccess}
        isAdded={isAdd}
      />
    );

    if (isSuccess) {
      return showSuccessAlert({ description, icon });
    }

    return showErrorAlert({ description, icon });
  };
};

export const FooterConnector = () => {
  const { data, updateData } = usePlayerData();
  const campaignId = data?.currentCampaignId;
  const isFavorite = data?.isFavoriteCampaign;
  const canBuy = data?.canBuy;
  const showAddFavoriteAlert = useFavoriteAlert('add');
  const showDeleteFavoriteAlert = useFavoriteAlert('delete');
  const mutationSubscribe = usePostFavorite(showAddFavoriteAlert);
  const mutationUnSubscribe = useDeleteFavorite(showDeleteFavoriteAlert);

  const handleToggleFavorite = async () => {
    if (!data?.currentCampaignId) {
      return;
    }

    if (!data?.isFavoriteCampaign) {
      mutationSubscribe.mutate(data.currentCampaignId, {
        onSuccess: () => {
          updateData({ isFavoriteCampaign: true });
        },
      });
      return;
    }

    mutationUnSubscribe.mutate(data.currentCampaignId, {
      onSuccess: () => {
        updateData({ isFavoriteCampaign: false });
      },
    });
  };

  const handleBuy = () => {
    if (campaignId === null) {
      return;
    }

    hardPush(`/project/purchase/${campaignId}`);
  };

  return (
    <PlayerFooter
      isLiked={isFavorite}
      canBuy={canBuy}
      onLike={handleToggleFavorite}
      onBuy={handleBuy}
    />
  );
};
