import { usePlayList } from '../hooks';
import { FullscreenPlayer } from './fullscreen-player/fullscreen-player';

import { TimeLineConnector } from './timeline-connector';
import { ControlsConnector } from './controls-connector';
import { useUnit } from 'effector-react';
import { toggleFullscreenPlayer, $fullscreenPlayer } from '../model';
import { FooterConnector } from '@/features/audio-player/ui/footer-connector';

export const FullscreenPlayerConnector = () => {
  const { currentTrack } = usePlayList();
  const [onToggleFullscreenPlayer, fullscreenPlayer] = useUnit([
    toggleFullscreenPlayer,
    $fullscreenPlayer,
  ]);

  return (
    <FullscreenPlayer
      data={{
        albumCoverUrl: currentTrack?.coverUrl,
        authorCoverUrl: currentTrack?.author.coverUrl,
        snippetName: currentTrack?.name,
        snippetAuthor: currentTrack?.author.name,
        id: currentTrack?.id,
      }}
      isOpen={fullscreenPlayer}
      playControls={
        <>
          <TimeLineConnector />
          <ControlsConnector />
        </>
      }
      onClose={() => onToggleFullscreenPlayer(false)}
      footer={<FooterConnector />}
    />
  );
};
