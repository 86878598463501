import { demuzoApi } from '@/shared/api';
import { useMutation } from '@tanstack/react-query';

export const useValidateNickname = () => {
  return useMutation({
    mutationFn: (nickname: string) =>
      demuzoApi.getUsersV1NicknameValidate({
        nickname,
      }),
  });
};
