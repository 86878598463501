import { Image } from '@chakra-ui/next-js';
import {
  Center,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';

import IconButton from '@ui-kit/atoms/icon-button/icon-button';
import IconClose from '@ui-kit/icons/icon-close';
import { ReactNode } from 'react';

type SnippetData = {
  albumCoverUrl?: string;
  authorCoverUrl?: string;
  snippetName?: string;
  snippetAuthor?: string;
  id?: string;
};

type Props = {
  data: SnippetData;
  isOpen: boolean;
  playControls: ReactNode;
  onClose: () => void;
  footer: ReactNode;
};

export const FullscreenPlayer = ({
  data,
  footer,
  playControls,
  isOpen,
  onClose,
}: Props) => {
  return (
    <Drawer
      isOpen={isOpen}
      onOverlayClick={onClose}
      placement='bottom'
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent
        h='100dvh'
        zIndex='tooltip'
        bg='transparent'
      >
        <Flex
          bg='grey.970'
          flexDirection='column'
          overflow='auto'
          flexGrow={1}
          borderTopLeftRadius={30}
          borderTopRightRadius={30}
          mt={2}
        >
          <Stack
            bg='grey.970'
            borderRadius='30px 30px 0 0'
            padding={2.5}
            flexGrow={1}
          >
            <Flex justifyContent='space-between'>
              <Image
                width={10}
                height={10}
                // style={{ objectFit: 'cover' }}
                borderRadius={40}
                src={data.authorCoverUrl ?? ''}
                loader={({ src }) => src}
                alt={data.snippetAuthor ?? ''}
              />

              <IconButton
                aria-label='close'
                color='white'
                size='sm'
                colorScheme='transparent'
                variant='solid'
                onClick={onClose}
              >
                <IconClose />
              </IconButton>
            </Flex>

            <Center
              width='216px'
              height='216px'
              pos='relative'
              mt={5}
              m='auto'
            >
              <Image
                fill
                sx={{ objectFit: 'cover' }}
                borderRadius={5}
                src={data.albumCoverUrl ?? ''}
                alt={data.snippetName ?? ''}
              />
            </Center>

            <Center
              flexFlow='column'
              gap={1}
              mt={2}
              mb={8}
              textAlign='center'
            >
              <Text textStyle='heading-small-lg'>{data.snippetName}</Text>
              <Text
                textStyle='text-regular-sm'
                color='grey.500'
              >
                {data.snippetAuthor}
              </Text>
            </Center>
            <Stack>{playControls}</Stack>
            {footer}
          </Stack>
        </Flex>
      </DrawerContent>
    </Drawer>
  );
};
