import { MiniPLayerMobileProps } from '@/features/audio-player/ui/mini-player/types';
import IconPause from '@/shared/ui-kit/icons/icon-pause';
import IconPlay from '@/shared/ui-kit/icons/icon-play';
import { Box, Button, Center, Flex, Stack, Text } from '@chakra-ui/react';

export const MiniPlayerMobile = ({
  onOpenPlayer,
  data,
  buySlot,
  isLoading,
  playControl,
}: MiniPLayerMobileProps) => {
  return (
    <Box
      position='fixed'
      bottom='10px'
      left='10px'
      right='10px'
      zIndex={100}
    >
      <Flex gap={1.5}>
        <Flex
          gap={2.5}
          grow={1}
          padding={2.5}
          alignItems='center'
          borderRadius={30}
          bg='white'
        >
          <Box
            boxSize='40px'
            flexShrink={0}
            borderRadius='full'
            onClick={onOpenPlayer}
            bgImage={data.coverUrl}
            bgSize='cover'
            bgPos='center'
            bgRepeat='no-repeat'
          />

          <Center
            w='auto'
            onClick={onOpenPlayer}
          >
            <Stack gap={0}>
              <Text
                noOfLines={1}
                textStyle='one-line-text-medium-xs'
                color='grey.900'
              >
                {data.snippetName}
              </Text>
              <Text
                noOfLines={1}
                textStyle='one-line-text-regular-xs'
                color='grey.500'
              >
                {data.snippetAuthor}
              </Text>
            </Stack>
          </Center>
          <Center
            w='40px'
            h='40px'
            marginLeft='auto'
          >
            <Button
              colorScheme='black'
              padding={0}
              size='sm'
              isLoading={isLoading}
              onClick={playControl.onClick}
            >
              {playControl.isPlaying ? <IconPause /> : <IconPlay />}
            </Button>
          </Center>
        </Flex>
        {buySlot}
      </Flex>
    </Box>
  );
};
