import { demuzoApi } from '@/shared/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { productsKeys } from '@/entities/product/model/query-keys';
import { profileKeys } from '@/entities/profile/model/query-keys';

export const usePostFavorite = (showMessage?: (isSuccess: boolean) => void) => {
  const query = useQueryClient();
  return useMutation({
    mutationFn: (productId: string) =>
      demuzoApi.postNewsV1UserFavorites({
        postDeleteUserFavoriteRequest: { productId },
      }),
    onSuccess: () => {
      query.invalidateQueries({ queryKey: productsKeys.all });
      query.invalidateQueries({ queryKey: profileKeys.profileFavourites() });
      showMessage?.(true);
    },
    onError: () => showMessage?.(false),
  });
};
